<template>
  <div>
    <section class="section">
      <template v-if="video == null">
        loading skeleton
      </template>
      <template v-else>
        <video :src="video.url" disableRemotePlayback muted autoplay loop></video>
        <a :href="video.url" download class="button" _target="blank">Guardar</a>
        <b-button @click="ShareVideo()" :loading="fetchingVideoShare">Compartir</b-button>
      </template>

    </section>
  </div>
</template>

<script scoped>
  import axios from 'axios';

  export default {
    data() {
      return {
        video: null,
        fetchingVideoShare: false,
      };
    },
    methods: {
      Reload() {
        window.location.href="/";
      },
      async ShareVideo(){
        try {
          this.fetchingVideoShare = true;
          const response = await fetch(this.video.url);
          if (!response.ok) {
            this.fetchingVideoShare = false;
            throw new Error('Network response was not ok');
          }

          const videoBlob = await response.blob();
          console.log('Blob type:', videoBlob.type); // Log the blob type

          const videoFile = new File([videoBlob], 'TOM Experiencia Photobooth.mp4', { type: 'video/mp4' });
          console.log('File:', videoFile); // Log the file

          let shareData = {
            files: [videoFile],
          };
          console.log(shareData)
          
          if (navigator.share && typeof navigator.share === 'function') {
            if (navigator.canShare && navigator.canShare(shareData)) {
              this.fetchingVideoShare = false;
              await navigator.share(shareData);
            } else {
              // File sharing not supported
              console.error('File sharing is not supported in this browser.');
              this.fetchingVideoShare = false;
            }
          } else {
            // Fallback for browsers that do not support Web Share API
            // Convert blob to data URL and provide a link or use other methods for sharing
            const reader = new FileReader();
            reader.onload = function(event) {
              const dataUrl = event.target.result;
              // Use the data URL for sharing or provide a download link
              console.log('Data URL:', dataUrl);
              // Handle sharing or provide fallback for sharing on non-supported browsers
            };
            reader.readAsDataURL(videoBlob);
            this.fetchingVideoShare = false;
          }
        } catch (error) {
          // Handle errors here
          console.error('Error fetching and sharing video:', error);
          this.fetchingVideoShare = false;
        }
      }
    },
    async mounted() {
      const resVideo = await axios.get(process.env.VUE_APP_API_BASE + `/api/uservideos/${this.$route.params.id}`);
      if(resVideo.status == 200){
        // console.log("res", resVideo)
        this.video = resVideo.data;
      }else{
        console.warn("Error al cargar las fotos", resVideo)
      }
      // stop loading
      this.$store.commit("StopLoading");
    }
  };
</script>
  
<style lang="scss" scoped>
@import '@/styles/variables.scss';
@import '@/styles/mixins.scss';

</style>